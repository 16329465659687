import React from "react";

import { PageHeader } from "@transfr-inc/dashboard-components/layouts";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { Breadcrumb, Toolbar } from "@transfr-inc/dashboard-components";
import { Category } from "../../../../constants/category.model";
import "./page.header.scss";

export const CategoryDetailPageHeader = ({
  category,
  breadcrumb,
  onEditCategoryClick,
  onDeleteCategoryClick,
}) => {
  const canEdit = category?.categoryType === Category.INTERNAL_CUSTOM;
  const toolbar = (
    <Toolbar>
      <Button
        icon={["fa-regular", "pen-to-square"]}
        title={"Edit Category"}
        onClick={onEditCategoryClick}
      />
      <Button
        icon={["fa-regular", "trash-can"]}
        title={"Delete Category"}
        onClick={onDeleteCategoryClick}
      />
    </Toolbar>
  );

  return (
    <>
      <PageHeader
        title={category?.name ?? "Loading..."}
        subTitle={category?.description ?? "Loading..."}
        breadcrumb={<Breadcrumb label={breadcrumb} />}
        drawerColorEnabled
        className={"detail"}
      >
        {canEdit && toolbar}
      </PageHeader>
    </>
  );
};
