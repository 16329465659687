import React from "react";
import { useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Link,
  OverflowText,
} from "@transfr-inc/dashboard-components";
import {
  SearchBox,
} from "@transfr-inc/dashboard-components/forms";

import { OrganizationsRoute, RootPath } from "../../../../components/navbar";

import "./menu-orgs.modal.scss";

export function MenuOrgsModal({
  open,
  menu,
  onClose,
  breadcrumbLabel,
}) {
  const { path } = useRouteMatch(RootPath);

  const renderOrgOptionLabel = (org) => {
    return (
      <Link
        className="org-option-link"
        to={{
          pathname: `${path}${OrganizationsRoute.path}/${org.code}`,
          state: {
            code: org.code,
            type: org.typeId,
            breadcrumbLabel,
          },
        }}
        underline={false}
      >
        <OverflowText text={org.name}>
          {`${org.name}`}
        </OverflowText>
        <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
      </Link>
    );
  }

  return (
    <Modal
      modalClassName={"menu-orgs-modal"}
      open={open}
      onClose={onClose}
    >
      <ModalHeader
        label="Organizations"
      >
        <div className="modal-title">
          <h2>{menu.name}</h2>
          <Badge value={menu.organizations.length} />
        </div>
      </ModalHeader>
      <ModalBody>
        <SearchBox
          className="orgs-search-box"
          placeholder="Search Organizations..." 
          data={menu.organizations}
          searchKey="name"
          customOptionLabel={renderOrgOptionLabel}
          clearInputEnabled={false}
          defaultShowAllData
        ></SearchBox>
      </ModalBody>
    </Modal>
  );
}
