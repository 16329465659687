import React, { useContext } from "react";

import {
  OrganizationsRoute,
  UsersRoute,
  RootPath,
  SearchRoute,
  TemplatesRoute,
} from "./routes";
import {
  NavBar,
  NavBarItem,
  NavBarSection,
} from "@transfr-inc/dashboard-components";
import { useRouteMatch } from "react-router-dom";
import { ProfileNavBarItem } from ".";

import {
  OrganizationContext,
  SessionContext,
  UsersContext,
} from "../../context";
import { toggles } from "../../dependencies";

import "./navbar.scss";

export default ({ isExpanded, onSkipNavigation }) => {
  const { url } = useRouteMatch(RootPath);
  const { currentUser, role } = useContext(SessionContext);
  const { usersCount } = useContext(UsersContext);
  const { orgsCount } = useContext(OrganizationContext);
  const { menuBuilderEnabled } = toggles;

  const firstNavItem = (
    <NavBarItem
      to={`${url}${OrganizationsRoute.path}`}
      isExpanded={isExpanded}
      icon={OrganizationsRoute.icon}
      label={OrganizationsRoute.label}
      labelAbbr={OrganizationsRoute.labelAbbr}
      badge={orgsCount}
      exact={true}
      component={OrganizationsRoute.component}
    ></NavBarItem>
  );

  const secondNavItem = (
    <NavBarItem
      to={`${url}${UsersRoute.path}`}
      isExpanded={isExpanded}
      icon={UsersRoute.icon}
      label={UsersRoute.label}
      labelAbbr={UsersRoute.labelAbbr}
      badge={usersCount}
      exact={true}
      component={UsersRoute.component}
    ></NavBarItem>
  );

  const thirdNavItem = (
    <NavBarItem
      to={`${url}${SearchRoute.path}`}
      isExpanded={isExpanded}
      icon={SearchRoute.icon}
      label={SearchRoute.label}
      labelAbbr={SearchRoute.labelAbbr}
      exact={true}
      component={SearchRoute.component}
    ></NavBarItem>
  );

  const fourthNavItem = (
    <NavBarItem
      to={`${url}${TemplatesRoute.path}`}
      isExpanded={isExpanded}
      icon={TemplatesRoute.icon}
      label={TemplatesRoute.label}
      labelAbbr={TemplatesRoute.labelAbbr}
      exact={true}
      component={TemplatesRoute.component}
    ></NavBarItem>
  );

  return (
    <NavBar
      className="client-portal-navbar"
      onSkipNavigation={onSkipNavigation}
      subtitle="Internal Portal"
    >
      <NavBarSection>
        {firstNavItem}
        {secondNavItem}
        {thirdNavItem}
        {menuBuilderEnabled && fourthNavItem}
      </NavBarSection>
      <NavBarSection className="bottom-section">
        <ProfileNavBarItem user={currentUser} role={role}></ProfileNavBarItem>
      </NavBarSection>
    </NavBar>
  );
};
