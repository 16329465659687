import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Column,
  ColumnType,
  ColumnOptions,
  TableCellListContent,
  UserCell,
  getSortKeyFunctionForTableCellList,
} from "@transfr-inc/dashboard-components/data-table";

import {
  EmailLink,
  OverflowText,
  HyphenIcon,
  TextAvatar,
} from "@transfr-inc/dashboard-components";

export const ClientUsersColumns = {
  ORGANIZATION: new Column(
    "organizationNames",
    "Organizations",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <TableCellListContent
            collection={value}
            collectionCount={row.organizationCount}
          />
        );
      },
      sortKeyFunctions: [
        getSortKeyFunctionForTableCellList(
          "organizationNames",
          "organizationCount"
        ),
      ],
    })
  ),
  FULL_NAME: new Column(
    "name",
    "Last Name, First Name",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <UserCell
            lastName={row.lastName}
            firstName={row.firstName}
            userId={row.id}
          ></UserCell>
        );
      },
      sortKeys: ["lastName", "firstName"],
    })
  ),
  FIRST_NAME: new Column(
    "firstName",
    "First Name",
    ColumnType.BASIC,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
    })
  ),
  LAST_NAME: new Column(
    "lastName",
    "Last Name",
    ColumnType.BASIC,
    new ColumnOptions({
      sortingEnabled: true,
      overflowEnabled: true,
    })
  ),
  AVATAR: new Column(
    "avatar",
    "",
    ColumnType.CUSTOM,
    new ColumnOptions({
      customFunction: (value, row) => (
        <TextAvatar text={[row.lastName, row.firstName]} />
      ),
    })
  ),
  EMAIL: new Column(
    "email",
    "Email",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value) => {
        return value ? (
          <OverflowText text={value}>
            <EmailLink>{value}</EmailLink>
          </OverflowText>
        ) : (
          <HyphenIcon></HyphenIcon>
        );
      },
    })
  ),

  CLASSROOM: new Column(
    "classroomNames",
    "Classrooms",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value, row) => {
        return (
          <TableCellListContent
            collection={value}
            collectionCount={row.classroomCount}
          />
        );
      },
      sortKeyFunctions: [
        getSortKeyFunctionForTableCellList("classroomNames", "classroomCount"),
      ],
    })
  ),
  USERNAME: new Column(
    "username",
    "Username",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value) => (
        <OverflowText text={value}>
          <span>{value}</span>
        </OverflowText>
      ),
    })
  ),
};
