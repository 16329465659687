import React from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";

import {
  Link,
  ProfileAvatar,
  RoleBadge,
} from "@transfr-inc/dashboard-components";
import { RootPath } from "./routes";

import "./navbar-item.profile.scss";

export default ({ isExpanded, user, role }) => {
  const { url: rootPath } = useRouteMatch(RootPath);

  return (
    <Link
      underline={false}
      to={`${rootPath}/profile`}
      className={clsx("profile-item", !isExpanded && "collapsed")}
    >
      <ProfileAvatar className="role-avatar" fillColor="#FFCD00" />
      <div className="description">
        <div className={clsx("name", !isExpanded && "collapsed")}>
          {user?.firstName} {isExpanded && user?.lastName}
        </div>
        {isExpanded && <RoleBadge role={role} small></RoleBadge>}
      </div>
    </Link>
  );
};
