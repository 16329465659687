import { Controller, useFormContext } from "react-hook-form";
import { RequiredItem } from "@transfr-inc/dashboard-components";
import {
  Input,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
  Select,
  Textarea,
  OptionItem,
} from "@transfr-inc/dashboard-components/forms";
import {
  getProducts,
  getProduct,
  Product,
} from "@transfr-inc/dashboard-components/utils";

import "./menu-template.scss";

export const MenuTemplateForm = ({
  defaultValues,
  register,
  control,
  formState,
  categories,
}) => {
  if (!register && !formState && !control) {
    ({ register, formState, control } = useFormContext());
  } else if (!register || !formState || !control) {
    throw (
      "MenuTemplate: for register, control, and formState, all values must be specified or entirely left unspecified " +
      "Specifying a value for only the errors prop, for example, will result in this error message."
    );
  }

  const { errors } = formState;

  const renderMenuNameInput = () => {
    return (
      <Controller
        control={control}
        name="name"
        defaultValue={defaultValues?.name}
        rules={{
          required: {
            value: true,
            message: "Required fields are missing.",
          },
          maxLength: 50,
          pattern: {
            value: /^[\d ',.a-z-]+$/i,
            message:
              "Name formatted incorrectly. Only letters, hyphens, numbers, and spaces are allowed.",
          },
        }}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <div className="menu-template-field">
              <label>
                Menu Name <sup>*</sup>
              </label>
              <Input
                label={
                  <RequiredItem
                    className="menu-template-input-label"
                    text="Name"
                  />
                }
                type="text"
                name="name"
                errors={errors?.name}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                value={value ?? ""}
                defaultValue={defaultValues?.name}
              />
            </div>
          );
        }}
      />
    );
  };

  const renderProductDropdown = () => {
    return (
      <Controller
        control={control}
        name="product"
        defaultValue={defaultValues?.product ?? Product.TS}
        rules={{
          required: {
            value: true,
            message: "Required fields are missing.",
          },
        }}
        render={({ field: { onChange, value } }) => {
          const selectedValue = getProduct(value);
          return (
            <div className="menu-template-field">
              <label>
                Product <sup>*</sup>
              </label>
              <Dropdown
                selected={selectedValue}
                onOptionSelected={(selected) => onChange(selected.id)}
                disabled
              >
                <DropdownButton
                  placeholder={"Select a product type..."}
                  icon={selectedValue.icon}
                >
                  {selectedValue.displayName}
                </DropdownButton>
                <DropdownItems>
                  {getProducts().map((type) => (
                    <DropdownItem key={type.id} value={type} propName="id">
                      {type.displayName}
                    </DropdownItem>
                  ))}
                </DropdownItems>
              </Dropdown>
            </div>
          );
        }}
      />
    );
  };

  const renderTSCategoriesSelect = () => {
    return (
      <Controller
        control={control}
        name="categories"
        defaultValue={defaultValues?.categories}
        rules={{
          required: {
            value: false,
          },
        }}
        render={({ field: { onBlur, onChange, value } }) => {
          return (
            <div className="menu-template-field">
              <label>TS Categories</label>
              <Select
                placeholder="Select one or more TS categories..."
                icon="search"
                onOptionsChange={(options) => onChange(options)}
                options={categories?.map(
                  (category) =>
                    new OptionItem({
                      id: category.id,
                      text: category.name,
                      data: category,
                    })
                )}
                selected={value}
                onBlur={onBlur}
                clearEnabled={false}
                searchEnabled
              ></Select>
            </div>
          );
        }}
      />
    );
  };

  const renderDescriptionTextarea = () => {
    return (
      <Controller
        control={control}
        name="description"
        defaultValue={defaultValues?.description}
        rules={{
          required: {
            value: false,
          },
        }}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <div className="menu-template-field">
              <Textarea
                label="Menu Description"
                name="description"
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                value={value ?? ""}
                defaultValue={defaultValues?.description}
              />
            </div>
          );
        }}
      />
    );
  };

  return (
    <div className="menu-template-form">
      {renderMenuNameInput()}
      {renderProductDropdown()}
      {renderTSCategoriesSelect()}
      {renderDescriptionTextarea()}
    </div>
  );
};
