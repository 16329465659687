import React from "react";
import clsx from "clsx";

import { Link } from "@transfr-inc/dashboard-components";

import "./footer.scss";

export default function Footer({ className }) {
  return (
    <footer className={clsx(className)}>
      <div>
        Copyright &copy; <span id="current-year">2022</span> Transfr Inc. All
        Rights Reserved.
      </div>
      <ul>
        <li>
          <Link to={"https://www.transfrvr.com/privacy"} underline isExternal>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            to={"https://www.transfrvr.com/termsofuse"}
            underline
            isExternal
          >
            Terms of Use
          </Link>
        </li>
      </ul>
    </footer>
  );
}
