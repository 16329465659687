import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";

import { 
  Badge, 
  HyphenIcon,
  Loader,
  Link,
  TextAvatar,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { services } from "../../../../dependencies";
import { RootPath, TemplatesRoute } from "../../../../components/navbar";

import "./menus.section.scss";

export const MenusSection = ({
  category,
  className,
}) => {
  const [loading, setLoading] = useState();
  const [menus, setMenus] = useState([]);

  const { path } = useRouteMatch(RootPath + TemplatesRoute.path);

  const { menuBuilderService } = services;

  const fetchCategoryMenus = async () => {
    setLoading(true);
    if (category?.id) {
      const menus = await menuBuilderService.getCategoryMenus(category?.id);
      setMenus(menus);
    }
    setLoading();
  };

  useEffect(() => {
    fetchCategoryMenus();
  }, [category]);

  if (!menus || menus?.length === 0) {
    return (
      <div className={clsx("menu-list-container", className)}>
        <div className="menus-header">
          <span className="modules-header-title">Menus</span>
        </div>
        <div className="no-menus-container">
          {loading && <Loader overlay></Loader>}
          <span className="no-menus-message">
            There are no menus configured with this category.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx("menu-list-container", className)}>
      <div className="menus-header">
        <span>Menus</span>
        <div className="menus-list">
          {menus.map((menu) => (
            <div key={menu.id} className="menu-box">
              <TextAvatar text={[menu.name]} />
              <Link
                to={{
                  pathname: `${path}/menus/${menu.id}`,
                  state: {
                    data: menu,
                    breadcrumbLabel: "templates",
                  }
                }}
              >
                {menu.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
