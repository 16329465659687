import React from "react";

import {
  Column,
  ColumnType,
  ColumnOptions,
} from "@transfr-inc/dashboard-components/data-table";

import { EmailLink } from "@transfr-inc/dashboard-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UsersColumnDefinition = {
  NAME: (columnOptions, userId) => {
    return new Column(
      "name",
      "Name",
      ColumnType.CUSTOM,
      Object.assign(
        new ColumnOptions({
          sortingEnabled: true,
          customFunction: (value, row) => {
            return (
              <div className="user-name-cell">
                {`${row.lastName}, ${row.firstName}`}{" "}
                {userId === row.userId && <span className="badge">YOU</span>}
                {!row.isSetup && <span className="pending">PENDING</span>}
              </div>
            );
          },
          sortKeyFields: ["lastName", "firstName"],
        }),
        columnOptions
      )
    );
  },
  EMAIL: new Column(
    "email",
    "Email",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      customFunction: (value) => {
        return value ? (
          <EmailLink>{value}</EmailLink>
        ) : (
          <FontAwesomeIcon icon="fa-thin fa-hyphen" />
        );
      },
    })
  ),
};
